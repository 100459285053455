<template>
  <div style="max-width: 100rem; margin: auto;">
    <b-card title="Key Manager">
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
      >
        <b-row>
           <b-col cols="3">
            <b-form-group
              label="Active Key"
            >
              <b-form-input
                id="item-name"
                type="text"
                v-model="params.prefix"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Number Key"
              :description="desc"
            >
              <b-form-input
                id="item-name"
                type="number"
                mix="1"
                max="1000"
                step="1"
                v-model="params.qty"
              />
              </b-form-group>
          </b-col>
         

          <b-col cols="3">
            <b-form-group
              label="Action"
              label-for="item-name"
            >
              <b-button
                @click="addKey"
              >
                  Add key
                </b-button>
            </b-form-group>
             
            </b-col>
        </b-row>
      
      </b-form>
    </b-card>

    <b-card style="max-width: 150rem; margin: auto;">
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
    <b-card-header class="pb-50">
      <b-card-title>List Key</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
        <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                  @input="isEmptyHandle"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="onChangeText"
                    
                  >
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      ref="tablea"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <b-badge :variant="data.item.status == 'Active' ? 'success' : 'warning'">
          {{data.item.status}}
        </b-badge>
      </template>

      <template #cell(actived_at)="data">
        {{data.item.actived_at ? convertUTCDateToLocalDate(data.item.actived_at) : ''}}
      </template>
      <template #cell(_id)=data>
        <div class="text-nowrap">
            <b-button
              v-if="data.item.serial"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="resetKey(data.item)"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
        </div>
        <div class="text-nowrap">
          
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
     </b-overlay>
  </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,  BFormGroup, BFormInput,BBadge, BInputGroup,BOverlay,BTable, BInputGroupAppend,BPagination,BCardHeader, BCardTitle, BCardBody, BForm, BFormCheckbox, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const NPlansRepository = RepositoryFactory.get('nplans')
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardActions,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormSelect,
    BPagination,
    BOverlay,
    BTable,
    BBadge
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      variants:[
        "success",
        "warning",
        "danger"
      ],
      show: false,
      variant: 'dark',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        //'STT',
        { key: 'active_key', label: 'Active Key', sortable: true },
        { key: 'serial', label: 'Serial', sortable: true },
        { key: 'actived_at', label: 'Date Actived', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: '_id', label: 'Action' },
      ],
      params: {
        prefix: '',
        qty: 1,
        password: '',
        plan_id: this.$route.params.id
      },
      items:[], 
      base_items: [],
      plan: null,
      psettings: this.$store.getters['auth/settings'],
    }
  },
  created(){
    this.$gtag.event('route', { path: 'plan-manager' })
    NPlansRepository.getKey(this.$route.params.id).then(rs=>{
      this.items = rs.data.data
      this.base_items = rs.data.data
      this.plan = rs.data.plan
    })
    window.addEventListener('resize', this.initTrHeight)
    
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    desc() {
      if(this.plan){
        const availkey = this.plan.qty - this.base_items.length
        return `Key availble: ${availkey}`
      }
      return ''
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods:{
    onChangeText(){
      this.items = this.base_items.filter(x=>(x.username.includes(this.filter) || x.email.includes(this.filter)))
      this.totalRows = this.items.length
    },

    isEmptyHandle(){
      if(this.filter == ""){
        this.items = this.base_items
        this.totalRows = this.items.length
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    addKey(){
      if (this.plan.qty - this.base_items.length <= 0)
      {
        this.$bvToast.toast('Maxium key can add reached', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          return
      }

      if(this.params.prefix == null || this.params.prefix == ""){
        this.$bvToast.toast('Name cannot be empty', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          return
      }

      if(this.params.prefix.length < 7){
        this.$bvToast.toast('Active key must be greater than 7 character', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          return
      }

      if(/^\d/.test(this.params.prefix)){
        this.$bvToast.toast('Active key cannot start with number', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          return
      }

      if(!/[a-z]{7}/.test(this.params.prefix)){
        this.$bvToast.toast('Active key must be start with 7 character', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          return
      }

      NPlansRepository.addKey(this.params).then(rs=>{
        if (rs.data.success){

          rs.data.data.forEach(element => {
            this.base_items.push(element)
            this.items = this.base_items
          });
          
        }else{
          this.$bvToast.toast(rs.data.error, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
      })
    },

    getPriceFromQTY(qty){
      if(qty > 0 && qty <= 5)
      {
        return this.psettings.price_1
      }
      else if(qty > 5 && qty <= 10)
      {
        return this.psettings.price_5
      }
      else if(qty > 10 && qty <= 20)
      {
        return this.psettings.price_10
      }
      else if(qty > 20 && qty <= 50)
      {
        return this.psettings.price_20
      }
      else if(qty > 50 && qty <= 100)
      {
        return this.psettings.price_50
      }
      else if(qty > 100 && qty <= 200)
      {
        return this.psettings.price_100
      }
      else if(qty > 200 && qty <= 500)
      {
        return this.psettings.price_200
      }
      else if(qty > 500)
      {
        return this.psettings.price_500
      }
    },

    resetKey(id){
      if(id.serial == "")
      {
        return
      }

      const amout = (this.getPriceFromQTY(this.plan.qty)*10) / 100
      this.$swal({
        title: 'Are you sure?',
        text: `You will pay ${amout}$ to reset this key`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reset',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPlansRepository.resetKey(id._id).then(rs=>{
            if(rs.data.success){
              id.serial = ""
              id.actived_at = null

              this.$bvToast.toast("Reset key success", {
                title: 'Success',
                variant: 'success',
                solid: false,
              })
            }else{
              this.$bvToast.toast(rs.data.error, {
                title: 'Error',
                variant: 'danger',
                solid: false,
              })
            }
           
          })
        }
      })
      
    },

    convertUTCDateToLocalDate(d) {
      if(!d)
        return
      let date = new Date(d)
      return date.toLocaleString('vi-VN') 
    },
  
  }
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>